import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'mianView',
        component: () => import('../views/mianView.vue')
    },
    {
        path: '/runseView',
        name: 'runseView',
        component: () => import('../views/runseView.vue')
    },
    {
        path: '/liuxue2View',
        name: 'liuxue2View',
        component: () => import('../views/liuxue2View.vue')
    },
    {
        // 课业辅导
        path: '/xiezuoView',
        name: 'xiezuoView',
        component: () => import('../views/xiezuoView.vue')
    },
    {
        path: '/liuxue3View',
        name: 'liuxue3View',
        component: () => import('../views/liuxue3View.vue')
    },
    {
        path: '/aboutView',
        name: 'aboutView',
        component: () => import('../views/aboutView.vue')
    },
    {
        path: '/erweimaView',
        name: 'erweimaView',
        component: () => import('../views/erweimaView.vue')
    },
]



const router = new VueRouter({
    routes
})

// router.beforeEach((to, from, next) => {
//     // 获取路由参数中的 paramName 值
//     const paramName = to.query.paramName;

//     // 设置网站标题
//     document.title = `${paramName || '默认标题'} - 你的网站标题`;

//     // 设置其他元标记，例如描述信息
//     VueMeta.setMetaInfo({
//         meta: {
//             description: `这是关于 ${paramName || '默认'} 的页面描述`
//         }
//     });

//     next();
// })





export default router