import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta' // 导入 VueMeta 插件
Vue.config.productionTip = false
Vue.use(VueMeta) // 使用 VueMeta 插件
document.title = '兔知教育，您的学术好帮手！'
const link = document.createElement('link');
link.rel = 'icon';
link.href = '/favicon.ico'; // 图标文件的路径
document.head.appendChild(link);

// 设置全局的关键字和网页简介
Vue.metaInfo = {
  meta: [
    {
      name: 'keywords', // 设置搜索关键字
      content: '1对1， 一对一，在线辅导，留学生辅导，留学生辅导机构，课业辅导，论文写作辅导，论文辅导，论文培训，写作辅导，论文润色，论文修改，英文润色，SCI润色，期刊' // 你的全局关键字
    },
    {
      name: 'description', // 设置 Meta 描述
      content: '兔知教育专注于一对一留学生学术辅导，覆盖英美加澳等主流留学国家，为留学生提供课业辅导、文章编辑润色、论文写作辅导、考前冲刺等优质学术支持。服务价格优惠，导师背景一流。免费咨询试听，下单立享首单8折优惠。' // 你的全局网页简介
    }
  ]
};

new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
