<template >
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
export default {
  name: "App",
  components: {
    // HelloWorld,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
h1 {
  font-weight: 600;
  font-size: 30px;
  color: rgb(255, 85, 85);
}
.tb {
  font-weight: 600;
  font-size: 20px;
  color: rgb(255, 85, 85);
}
body > * {
  padding: 0px;
  margin: -8px;
}
</style>
